import { useEffect, useState } from "react";
import styles from "./WellnessFocus.module.css";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { getCategoriesAPI } from "../../api/categoryApis";

const WellnessFocus = () => {
  const [WellnessFocusList, setWellnessFocusList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getAllWellnessFocusesListAPIHelper = async () => {
    try {
      setLoading(true);
      const response = await getCategoriesAPI();
      setWellnessFocusList(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllWellnessFocusesListAPIHelper();
  }, []);

  return (
    <Spin spinning={loading}>
      <div>
        <h1 className={styles.pageHeader}>Wellness Focus</h1>

        <div className={styles.WellnessFocusCardWrapper}>
          {WellnessFocusList?.length
            ? WellnessFocusList?.map((i) => (
                <div
                  key={i?.id}
                  className={styles.WellnessFocusCard}
                  onClick={() => navigate(`/wellness-focus/${i?.id}`)}
                >
                  <p className={styles.WellnessFocusCard_name}>{i?.name}</p>
                  <div className={styles.WellnessFocusCard_imageWrapper}>
                    <img
                      src={i?.image}
                      alt="wellness focus"
                      className={styles.WellnessFocusCard_image}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </Spin>
  );
};

export default WellnessFocus;