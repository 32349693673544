import axios from "../config/axiosConfig";

export const paymentInitiateAPI = async (data) => {
  try {
    const response = await axios.post(`/api/createCheckout`, data);
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getPaymentInfoAPI = async (paymentId) => {
  try {
    const response = await axios.get(`/api/getPaymentInfo?paymentId=${paymentId}`);
    return response;
  } catch (error) {
    throw Error(error);
  }
};
