import { useAuth0 } from "@auth0/auth0-react";
import { Result } from "antd";
import { useEffect } from "react";

const Auth0LoginRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  });
  return (
    <div>
      <Result title="You are being redirected to the Sign In / Sign Up page." />
    </div>
  );
};

export default Auth0LoginRedirect;
