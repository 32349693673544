import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const antdValidateNumber = (_, value) => {
  if (!value || /^\d+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter a valid number."));
};

export const openLinkInNewTab = (url) => {
  // Check if the URL starts with 'http://' or 'https://', if not, prepend 'https://'
  const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
  window.open(formattedUrl, '_blank'); // Opens a new tab with the formatted URL
};

export const antdMinNumberValidatorIDR = (_, value) => {
  const minValue = 100000; // Set your minimum value here
  if (value !== undefined && value < minValue) {
    return Promise.reject(new Error(`Value must be at least ${minValue}`));
  }
  return Promise.resolve();
};

export const antdMinNumberValidator = (_, value) => {
  const minValue = 1; // Set your minimum value here
  if (value !== undefined && value < minValue) {
    return Promise.reject(new Error(`Value must be at least ${minValue}`));
  }
  return Promise.resolve();
};

export const imageIsNotInS3 = (img) => {
  return img?.includes("https://");
};

export const checkContainsEmoji = (str) => {
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F900}-\u{1F9FF}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F650}-\u{1F67F}]/u;
  return emojiRegex.test(str);
};

export const formatAIData = (text) => {
  // Replace Markdown links with HTML links
  const formattedText = text.replace(
    /\[([^\]]+)\]\(([^)]+)\)/g,
    '<a href="$2">$1</a>'
  );

  // Add line breaks before numbered list items (e.g., "1. ", "2. ")
  const formattedWithLineBreaks = formattedText.replace(
    /(\d+\.\s+)/g,
    "<br/><br />$1"
  );

  return formattedWithLineBreaks;
};

export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function convertPaymentStatus(status) {
  if (status === "processing") {
    return "Your payment is currently being processed.";
  } else if (status === "cancelled") {
    return "Your payment has been cancelled.";
  } else if (status === "failed") {
    return "Your payment attempt has failed.";
  } else if (status === "successful") {
    return "Your payment has been successfully completed.";
  } else if (status === "paymentRequested") {
    return "Payment has been requested and is awaiting action.";
  } else if (status === "pending") {
    return "Your payment is pending and has not yet been processed.";
  } else {
    return "Your payment is pending and has not yet been processed.";
  }
}

export const convertUTCToLocalTime = (utcDate) => {
  const localDate = new Date(utcDate);
  return {
    hour: localDate.getHours(),
    minute: localDate.getMinutes(),
  };
};

export const getDuration = ( startDateTime, endDateTime ) => {
  const startTime = dayjs(startDateTime);
  const endTime = dayjs(endDateTime);

  const diffInMinutes = endTime.diff(startTime, "minute");
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};