import { Col, Form, Input, Modal, Slider, Spin } from "antd";
import styles from "./MemberReviewAddEditModal.module.css";
import { useEffect, useState } from "react";
import { createReviewsAPI, updateReviewsAPI } from "../../api/reviewsApis";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemberStore } from "../../store/memberStore";

const MemberReviewAddEditModal = ({ isOpen, onClose, editData }) => {
  const [form] = Form.useForm();
  const { getIdTokenClaims } = useAuth0();
  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);

  const [loader, setLoader] = useState(false);

  const onFinish = async (data) => {

    const finalData = editData ? {
      ...data,
      review_type: "member",
      review_type_id: memberAccountInfo?.id,
      reviewId: editData?.id
    } : {
      ...data,
      review_type: "member",
      review_type_id: memberAccountInfo?.id,
    };
    
    try {
      setLoader(true);
      const token = await getIdTokenClaims();

      if (editData) {
        await updateReviewsAPI({
          data: finalData,
          token: token.__raw,
        });
      } else {
        await createReviewsAPI({
          data: finalData,
          token: token.__raw,
        });
      }
      onClose();
      setLoader(false);
    } catch (error) {
      console.log("🚀 ~ onFinish ~ error:", error);
      setLoader(false);
    }
    //
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
    }
  }, [editData, form]);

  return (
    <Modal
      title={editData ? "Review update form" : "Review add form"}
      open={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={500}
    >
      <Spin spinning={loader}>
        <Form form={form}>
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              How effective did you find the practitioner's approach in
              improving your wellness?
            </p>
            <Form.Item name="q1">
              <Slider min={1} max={10} />
            </Form.Item>
            <div className={styles.sliderPointMessage}>
              <span>Not Effective</span>
              <span>Somewhat Effective</span>
              <span>Very Effective</span>
            </div>
          </Col>
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              How supported did you feel during your sessions with the
              practitioner?
            </p>
            <Form.Item name="q2">
              <Slider min={1} max={10} />
            </Form.Item>
            <div className={styles.sliderPointMessage}>
              <span>Not Effective</span>
              <span>Somewhat Effective</span>
              <span>Very Effective</span>
            </div>
          </Col>
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Did you feel that the services were tailored specifically to your
              needs and preferences?
            </p>
            <Form.Item name="q3">
              <Slider min={1} max={10} />
            </Form.Item>
            <div className={styles.sliderPointMessage}>
              <span>Not Effective</span>
              <span>Somewhat Effective</span>
              <span>Very Effective</span>
            </div>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>Review</p>
            <Form.Item name="reviewText">
              <Input.TextArea
                maxLength={1000}
                showCount={true}
                rows={8}
                placeholder="Please provide your Review"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <button
              className={styles.formSubmitButton}
              onClick={() => {
                form
                  .validateFields()
                  .then((value) => {
                    onFinish(value);
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              }}
            >
              {editData ? "Update" : "Submit"}
            </button>
          </Col>
        </Form>
      </Spin>
    </Modal>
  );
};

export default MemberReviewAddEditModal;