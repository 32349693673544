import Autocomplete from "react-google-autocomplete";

const LocationSearch = ({ sendLocationInfo, defaultValue }) => {
  const getPlace = (place) => {
    // the da
    console.log("place :>> ", place?.formatted_address);
    console.log(
      "LAT : > ",
      place.geometry.location.lat(),
      "LONG : > ",
      place.geometry.location.lng()
    );

    sendLocationInfo({
      lat: place?.geometry?.location?.lat(),
      long: place?.geometry?.location.lng(),
      place: place?.formatted_address,
    });
  };

  return (
    <div>
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        onPlaceSelected={(place) => getPlace(place)}
        defaultValue={defaultValue}
        style={{
          width: "100%",
          height: "32px",
          borderRadius: "6px",
          border: "1px solid #d9d9d9",
          padding: "0 11px",
        }}
      />
    </div>
  );
};

export default LocationSearch;
