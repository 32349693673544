import { Routes, Route, Navigate } from "react-router-dom";
import Member from "../pages/Member";
import Register from "../pages/MemberRegister";
import Auth0Callback from "../pages/Auth0Callback";
import Auth0LoginRedirect from "../pages/Auth0LoginRedirect";
import MemberListing from "../pages/MemberListing";
import MemberIntro from "../pages/MemberIntro";
import BecomeAMember from "../pages/BecomeAMember";
import UserProfileRegistration from "../pages/UserProfileRegistration";
import PaymentCallback from "../pages/PaymentCallback";
import Payment from "../pages/Payment";
import WellnessFocus from "../pages/WellnessFocus";
import WellnessFocusDetails from "../pages/WellnessFocusDetails";
import Appointments from "../pages/Appointments";

const Router = () => {
  return (
    <Routes>
      {/* member is the default route for now */}
      <Route index element={<Navigate to="/auth0LoginRedirect" />} />

      <Route path="/member/:memberUserId" element={<Member />} />
      <Route path="/member-intro/:memberUserId" element={<MemberIntro />} />
      <Route path="/member-register" element={<Register />} />
      <Route path="/auth0Callback" element={<Auth0Callback />} />
      <Route path="/auth0LoginRedirect" element={<Auth0LoginRedirect />} />
      <Route path="/member-list" element={<MemberListing />} />
      <Route path="/become-a-member" element={<BecomeAMember />} />
      <Route path="/user-register" element={<UserProfileRegistration />} />
      <Route path="/paymentCallback" element={<PaymentCallback />} />
      <Route path="/payment/:paymentId" element={<Payment />} />
      <Route path="/wellness-focus" element={<WellnessFocus />} />
      <Route path="/wellness-focus/:categoryId" element={<WellnessFocusDetails />} />
      <Route path="/appointments" element={<Appointments />} />
    </Routes>
  );
};

export default Router;