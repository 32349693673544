import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./PaymentCallback.module.css";

const PaymentCallback = () => {
  const navigate = useNavigate();

  // Get the search params object
  const [searchParams] = useSearchParams();

  // Extract query parameters
  const paymentStatus = searchParams.get("redirect_status");

  return (
    <div className={styles.successPage}>
      {paymentStatus === "succeeded" ? (
        <h1 className={styles.greenTitle}>Payment Successful!</h1>
      ) : (
        <h1 className={styles.redTitle}>Payment Cancelled</h1>
      )}

      <p className={styles.message}>
        {paymentStatus === "succeeded"
          ? "Thank you for your purchase."
          : " Your payment process has been cancelled. Please try again."}
      </p>

      {paymentStatus === "succeeded" ? (
        <button
          className={styles.greenContinueButton}
          onClick={() => navigate("/appointments")}
        >
          Continue
        </button>
      ) : (
        <button
          className={styles.redContinueButton}
          onClick={() => navigate("/appointments")}
        >
          Continue
        </button>
      )}
    </div>
  );
};

export default PaymentCallback;