import { useParams } from "react-router-dom";
import styles from "./WellnessFocusDetails.module.css";
import { useEffect, useState } from "react";
import { Divider, Empty, Spin } from "antd";
import { getCategoryDetailsAPI } from "../../api/categoryApis";
import { imageIsNotInS3 } from "../../utils";

const WellnessFocusDetails = () => {
  let { categoryId } = useParams();

  const [WellnessFocusData, setWellnessFocusData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllWellnessFocusDetailsAPIHelper = async () => {
    try {
      setLoading(true);
      const response = await getCategoryDetailsAPI(categoryId);
      setWellnessFocusData(response?.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllWellnessFocusDetailsAPIHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const handleNavigate = (link) => {
    // For opening in a new tab
    window.open(link, "_blank");
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.main}>
        {/* <div className={styles.WellnessFocus_imageWrapper}>
          <img
            src={WellnessFocusData?.image}
            alt="focus area"
            className={styles.WellnessFocus_image}
          />
        </div>
        <p className={styles.WellnessFocus_name}> {WellnessFocusData?.name}</p>
        <p className={styles.WellnessFocus_description}>
          {WellnessFocusData?.description}
        </p> */}

        <h1 className={styles.member_heading}>{WellnessFocusData?.name} Practitioners</h1>
        <Divider/>
        <div className={styles.memberCardsWrapper}>
          {WellnessFocusData?.memberDetails?.length ? (
            WellnessFocusData?.memberDetails?.map((i) => {
              return (
                <div key={i.id} className={styles.memberListCard}>
                  <div className={styles.memberListImageWrapper}>
                    <img
                      src={
                        imageIsNotInS3(i?.UserSetting?.image)
                          ? `${i?.UserSetting?.image}`
                          : `${process.env.REACT_APP_BASE_URL}${i?.UserSetting?.image}`
                      }
                      alt="member pic"
                      className={styles.memberListImage}
                    />
                  </div>
                  <div className={styles.memberListInfoWrapper}>
                    <div className={styles.memberListInfo}>
                      <p className={styles.memberListName}>
                        {i?.UserSetting?.firstName} {i?.UserSetting?.lastName}
                      </p>
                      <p className={styles.memberListTitle}>{i.title}</p>
                    </div>

                    <div
                      className={styles.memberListButton}
                      onClick={() =>
                        handleNavigate(`/member-intro/${i?.username}`)
                      }
                    >
                      View Details
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className={styles.empty_container}>
              <Empty />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default WellnessFocusDetails;